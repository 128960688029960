import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Skip Nav" description="Accessible skip-navigation-link React component for screen reader and keyboard users" mdxType="SEO" />
    <h1 {...{
      "id": "skip-nav"
    }}>{`Skip Nav`}</h1>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/skip-nav"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/skip-nav`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://webaim.org/techniques/skipnav/"
        }}>{`https://webaim.org/techniques/skipnav/`}</a></li>
    </ul>
    <p>{`Skip navigation link for screen reader and keyboard users. Because the main content is not usually the first thing in the document, it's valuable to provide a shortcut for keyboard and screen reader users to skip to the content.`}</p>
    <p>{`If the user does not navigate with the keyboard, they won't see the link.`}</p>
    <p>{`For a demo, click some empty space on this page to move focus to the document body, then hit the "tab" key You'll see the link pop up. Hit enter, then tab again. Rather than cycling through the navigation, you are tabbing through the main content of the page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`ReactDOM.render(
  <React.Fragment>
    {/* put the link at the top of your app */}
    <SkipNavLink />
    <div>
      <YourNav />
      {/* and the content next to your main content */}
      <SkipNavContent />
      <YourMainContent />
    </div>
  </React.Fragment>,
  rootNode
);
`}</code></pre>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/skip-nav`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/skip-nav`}</inlineCode>{`. Then import the components and styles:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/skip-nav
# or
yarn add @reach/skip-nav
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "skipnavlink"
    }}>{`SkipNavLink`}</h3>
    <p>{`Renders a link that remains hidden until focused to skip to the main content.`}</p>
    <h4 {...{
      "id": "skipnavlink-css-selectors"
    }}>{`SkipNavLink CSS Selectors`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-skip-link] {
}
[data-reach-skip-link]:focus {
}
`}</code></pre>
    <h4 {...{
      "id": "skipnavlink-props"
    }}>{`SkipNavLink Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#skipnavlink-anchor-props"
            }}><inlineCode parentName="a">{`a`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Cool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#skipnavlink-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "skipnavlink-anchor-props"
    }}>{`SkipNavLink anchor props`}</h5>
    <p>{`Element props are spread to the underlying link.`}</p>
    <h5 {...{
      "id": "skipnavlink-children"
    }}>{`SkipNavLink children`}</h5>
    <p><inlineCode parentName="p">{`children?: React.ReactNode`}</inlineCode></p>
    <p>{`Allows you to change the text for your preferred phrase or localization. If no children are passed, the link will display `}<inlineCode parentName="p">{`Skip to content`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<SkipNavLink>saltar al contenido</SkipNavLink>
`}</code></pre>
    <h3 {...{
      "id": "skipnavcontent"
    }}>{`SkipNavContent`}</h3>
    <p>{`Renders a div as the target for the link.`}</p>
    <h4 {...{
      "id": "skipnavlink-props-1"
    }}>{`SkipNavLink Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#skipnavcontent-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "skipnavlink-children-1"
    }}>{`SkipNavLink children`}</h5>
    <p><inlineCode parentName="p">{`children?: React.ReactNode`}</inlineCode></p>
    <p>{`You can place the `}<inlineCode parentName="p">{`SkipNavContent`}</inlineCode>{` element as a sibling to your main content or as a wrapper:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<SkipNavContent />
<YourMainContent />

// or

<SkipNavContent>
  <YourMainContent />
</SkipNavContent>
`}</code></pre>
    <p>{`Keep in mind it renders a `}<inlineCode parentName="p">{`div`}</inlineCode>{`, so it may mess affect your page styles depending on where it’s placed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      